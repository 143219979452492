module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BetGames.TV homepage","short_name":"homepage","start_url":"/","background_color":"#0A2B61","theme_color":"#0A2B61","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b8d4407819908c9cfb4bf0ef0f000d62"},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"c4016df2ee8c4c108522ee78f6e12b92","captureUncaught":true,"payload":{"environment":"production"},"autoInstrument":{"network":false,"log":false},"hostSafeList":["https://new.betgames.tv","https://stg-homepage.betgames.tv"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-33127408-1","anonymize":true},"environments":["production","development"]},
    }]
