// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-layout-layout-tsx": () => import("./../../../src/templates/Layout/Layout.tsx" /* webpackChunkName: "component---src-templates-layout-layout-tsx" */),
  "component---src-templates-not-found-page-layout-index-tsx": () => import("./../../../src/templates/NotFoundPageLayout/index.tsx" /* webpackChunkName: "component---src-templates-not-found-page-layout-index-tsx" */),
  "component---src-templates-not-old-enough-layout-index-tsx": () => import("./../../../src/templates/NotOldEnoughLayout/index.tsx" /* webpackChunkName: "component---src-templates-not-old-enough-layout-index-tsx" */),
  "component---src-templates-thank-you-page-layout-index-tsx": () => import("./../../../src/templates/ThankYouPageLayout/index.tsx" /* webpackChunkName: "component---src-templates-thank-you-page-layout-index-tsx" */)
}

